import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { format } from 'date-fns';

dayjs.extend(utc);

const getOrdinalSuffix = (day: number): string =>
  day > 3 && day < 21 ? 'th' : ['th', 'st', 'nd', 'rd'][day % 10] || 'th';

export const formatDate = (date: string): string => {
  const parsedDate = dayjs(date);
  return ` ${parsedDate.format('DD-MMM-YYYY, HH:mm')}`;
};

export const formatOnlyDate = (date: string): string => {
  const parsedDate = dayjs(date);
  return ` ${parsedDate.format('DD-MMM-YYYY')}`;
};

export const formatDateWithoutTime = (date?: string | Date | null): string =>
  date && !Number.isNaN(new Date(date).getTime())
    ? format(new Date(date), 'dd-MMM-yyyy')
    : '';
